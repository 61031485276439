.global_container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.container {
  width: 100%;
  height: 100vh;
  composes: box_shadow from global;
  border-radius: 10px;
  min-width: 70vw;
  min-height: 60vh;
  background-color: white;
}
