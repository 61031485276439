.space {
  width: 100%;
  height: 14vw;
}

.img-title{
  width: 400px;
  height: auto;
}

.logo-mp{
  padding-bottom: 5rem;
}

.grid-login{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50% 50%;
}

.child-grid-login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
