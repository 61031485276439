.container-input {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.container-input input {
    margin: 0.5rem 0px;
}

.form-select-mp {
    width: 100%;
    min-width: 150px;
}

.form-input{
    width: 100%;
    background-color: #f3f3f3;
    margin-top: 1rem;
    border-radius: 5px;
    border-width: 0px;
    padding: .8rem 1rem;
    font-size: var(--font-size-input);
}

::placeholder{
    color: #606060;
    opacity: 1;
    font-weight: 400;
}
