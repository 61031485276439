.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
}

.font-bold {
  font-weight: var(--bs-body-font-weight);
}

.font-medium {
  font-size: 2rem;
}

.title {
  font-size: 3rem;
}

.title-white {
  font-size: 6rem;
  color: white;
}

.font-white {
  color: white;
}

.login-backgroud {
  background-image: var(--login-background);
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 1024px; */
}

.msg-backgroud {
  background-image: url('/public/assets/fondo_modal.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 1024px; */
}

.main-background {
  background-image: url('/public/assets/fondo_msg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 1024px; */
}

.background-top-modal {
  background-image: url('/public/assets/background-top-modal.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 1024px; */
}

.bg-white {
  background-color: white;
}

.bg-green-ligth {
  background-color: var(--green-ligth-color);
}

.bg-green {
  background-color: var(--green-color);
}

.container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
  text-align: center;
  height: 100vh;
}

.container-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;
  text-align: center;
}

.max-container{
  max-width: 1024px;
}

.btn-primary {
  background-color: var(--primary-color);
}

.btn {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: bold;
  padding: 0.8rem 7rem 0.8rem 7rem;
}

.clickable {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0px;
}

.scroll-hidden {
  scrollbar-width: none;
  overflow-y: scroll;
}

.pb-6 {
  padding-bottom: 6rem;
}

.box_shadow {
  -webkit-box-shadow: 0px 4px 9px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 9px -1px rgba(0, 0, 0, 0.2);
}
