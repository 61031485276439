/* ttf -> truetype*/
/* ORF -> opentype*/
/* woff -> woff*/

@font-face {
  font-family: 'Goldplay-Regular';
  src: local('Goldplay-Regular'), url(/public/font/Goldplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Goldplay-Black';
  src: local('Goldplay-Black'), url(/public/font/Goldplay-Black.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Goldplay-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bs-body-font-weight: 700;
  --bs-btn-font-weight: 700;
  --primary-color: #0874bc;
  --secondary-color: #f3f3f3;
  --text-color: #606060;
  --font-size-input: 1.3rem;
  --green-color: #00d9c5;
  --green-ligth-color: #92ffff;
  --blue-color: #0372b9;
  --bs-primary-rgb: #0372b9;
  --login-background: url('/public/assets/fondo_portada.jpg');
  --modal-background: url('/public/assets/fondo_modal.jpg');
  --logo-mp: url('/public/assets/logo_coomevaMP.png');
}
