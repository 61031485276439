.field-form-float {
  width: 450px;
}

.grid-title {
  display: grid;
  grid-template-columns: 5% auto 10% 10%;
}

.grid-row {
  display: grid;
  grid-template-columns: 5% 75% 10% 10%;
  justify-items: center;
  align-items: center;
  border: 1px solid white;
  padding: 3px;
}

.grid-item {
  display: grid;
  width: 100%;
  justify-content: flex-start;
}

.space-top{
  padding-top: 4rem !important;
}
